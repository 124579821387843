<template>
  <div class="onboarding-main-wrapper container-xs">
    <transition name="float" appear>
      <header>
        <div class="onboarding-main-header">
          <span></span>
          <h1 class="onboarding-main-header-title">Elude</h1>
          <router-link
            v-if="$route.meta.skippable"
            to="/search"
            class="onboarding-main-header-skip-button"
            >SKIP</router-link
          >
        </div>
        <div
          v-if="typeof $route.meta.progress === 'number'"
          class="onboarding-progress"
        >
          <div
            class="onboarding-progress-bar"
            :style="{ width: progress + '%' }"
          ></div>
        </div>
      </header>
    </transition>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return { progress: 0 }
  },
  created() {
    this.init()
  },
  watch: {
    '$route.meta.progress': {
      handler(value) {
        if (typeof value !== 'number') return
        setTimeout(() => (this.progress = value))
      },
      immediate: true,
    },
  },
  computed: mapState('UserModule', ['user']),
  methods: {
    ...mapMutations('OnboardingModule', [
      'clearState',
      'setFirstName',
      'setAirportId',
    ]),
    ...mapActions('UserModule', ['getUser']),
    async init() {
      this.clearState()
      if (!this.user) await this.getUser()
      this.setFirstName(this.user.firstName)
      this.setAirportId(this.user.airportId)
    },
  },
}
</script>

<style scoped lang="scss">
.onboarding-main-wrapper {
  display: grid;
  height: 100%;
  gap: 3rem;
  grid-template-rows: auto 1fr;
  padding: 0 30px;
}
.onboarding-main-header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  &-title {
    font-size: 48px;
    font-family: GTSuper;
  }
  &-skip-button {
    font-family: DDin;
    font-size: 14px;
    justify-self: end;
    text-decoration: underline;
    text-underline-offset: 2px;
    padding: 0.5rem;
  }
}
.onboarding-progress {
  height: 5px;
  width: 100%;
  margin: 8px auto;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  &-bar {
    transition: width 0.5s var(--bezier-1);
    background: var(--color-primary);
    height: 100%;
  }
}
</style>
